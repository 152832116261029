import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHomeStyles } from './useHomeStyles';
import { TextLogo } from 'components/TextLogo/TextLogo';
import { MyAccount } from './MyAccount/MyAccount';
import { MyBalance } from './MyBalance/MyBalance';
import { Typography } from '@material-ui/core';
import { tHTML } from 'modules/i18n/intl';
import classNames from 'classnames';
import { Social } from 'components/Social/Social';
import { WalletIcon } from 'modules/icons/WalletIcon';
import { CollectionIcon } from 'modules/icons/CollectionIcon';
import { SettingIcon } from 'modules/icons/SettingIcon';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import MigrationVerifyDialog from '../../components/Dialogs/MigrationVerifyDialog';
import ImportAccountDialog from '../../components/Dialogs/ImportAccountDialog';

import WalletComponent from '../wallet';
import ActivityDetail from '../activityDetail';
import TransferSolPage from '../transfer/transferSol';
import TransferTrxPage from '../transfer/transferTrx';
import TransferPolkaFamilyPage from '../transfer/transferPolkaFamily';
import TransferEthFamilyPage from '../transfer/transferEthFamily';
import TransferErc20Page from '../transfer/transferErc20';
import TransferCrossChainPage from '../transfer/transferCrossChain';
import SettingsComponent from '../settings';
import CollectionsComponent from '../collections';
import CollectionDetailComponent from '../collectionDetail';

import { useUserSession, useUserSessionUpdate } from '../../state/user/hooks';
import {
  useCurrentAccount,
  useCurrentAccountUpdate,
} from '../../state/account/hooks';
import {
  useEnterPageFirstRefresh,
  useEnterPageFirstRefreshUpdate,
  useRestoreFromStorage,
  useShareC,
  useShareCUpdate,
} from '../../state/app/hooks';

import { useConstructLoginAccount } from '../../services/AccountService';
import {
  saveStoreToLocal,
  useInitStoreFromStorage,
  getUserSessionFromSessionStorage,
} from '../../services/LocalstorageService';
import { useSetInterceptor } from '../../services/interceptorService';
import { getMigrationRequest } from '../../services/loginService';
import { logoutWallet, startListenActive } from '../../services/logoutService';
import {
  getToast,
  toastLoading,
  toastDismiss,
} from '../../services/toastService';
import eventEmitter, {
  EVENT_LOGOUT,
  EVENT_PAGE_OPERATION,
} from '../../services/eventService';
import { useUpdateBalance } from '../../services/BalanceWatcherServicer';

import { CUSTOM_EVMS } from '../../constants/netEnums';
import {
  useRefreshAllPendingTxStatus,
  REFRESH_INTERVAL,
} from '../../services/web3TxRetryService';
import _ from 'lodash';
import SaveShareDialog from '../../components/Dialogs/SaveShareDialog';

export default function HomePage(props) {
  const classes = useHomeStyles();

  const [migVerifyOpen, setMigVerifyOpen] = useState(false);

  const [importAccountOpen, setImportAccountOpen] = useState(false);
  const [saveShareOpen, setSaveShareOpen] = useState(false);

  const history = useHistory();
  const constructLoginAccount = useConstructLoginAccount();
  const userSession = useUserSession();
  const updateUserSession = useUserSessionUpdate();
  const currentAccount = useCurrentAccount();
  const updateCurrentAccount = useCurrentAccountUpdate();
  const initStoreFromStorage = useInitStoreFromStorage();
  const restoreFromStorage = useRestoreFromStorage();
  const setInterceptor = useSetInterceptor();

  const updateBalance = useUpdateBalance();
  const enterPageFirstRefresh = useEnterPageFirstRefresh();
  const updateEnterPageFirstRefresh = useEnterPageFirstRefreshUpdate();
  const refreshAllPendingTxStatus = useRefreshAllPendingTxStatus();
  const shareC = useShareC();
  const updateShareC = useShareCUpdate();

  const tabs = [
    {
      icon: <WalletIcon />,
      name: 'Wallet',
      path: `${props.match.url}/wallet`,
    },
    {
      icon: <CollectionIcon />,
      name: 'Collections',
      path: `${props.match.url}/collections`,
    },
    {
      icon: <SettingIcon />,
      name: 'Settings',
      path: `${props.match.url}/settings`,
    },
  ];
  const [selectTab, setSelectTab] = useState(0);

  useEffect(() => {
    if (_.includes(window.location.href, '/homePage/wallet')) {
      setSelectTab(0);
    } else if (_.includes(window.location.href, '/collection')) {
      setSelectTab(1);
    } else if (_.includes(window.location.href, '/settings')) {
      setSelectTab(2);
    }
  }, [setSelectTab]);

  const loadData = async () => {
    const loginAccount = await constructLoginAccount();
    toastDismiss();
    if (loginAccount === null) {
      logoutWallet(history);
      // throw new Error('Invalid login account')
    }
  };

  const logout = useCallback(() => {
    logoutWallet(history);
  }, [history]);

  const restoreUserSessionFromSessionStorage = useCallback(() => {
    if (userSession.userId >= 0) {
      return;
    }

    //try to restore user session from session storage
    const user = getUserSessionFromSessionStorage();

    updateUserSession(user);
  }, [updateUserSession, userSession.userId]);

  useEffect(() => {
    const user = getUserSessionFromSessionStorage();
    if (_.isEmpty(user) || user.userId < 0) {
      logoutWallet(history);
      return;
    }

    restoreUserSessionFromSessionStorage();

    if (_.isEmpty(shareC)) {
      toastLoading('Loading...');
    }

    setInterceptor();

    startListenActive();
    eventEmitter.on(EVENT_LOGOUT, logout);

    if (!_.isEmpty(shareC) && shareC.userId === user.userId && shareC !== '') {
      setTimeout(() => {
        setSaveShareOpen(true);
      }, 1000);
    }

    return () => {
      eventEmitter.off(EVENT_LOGOUT, logout);
      toastDismiss();
      updateEnterPageFirstRefresh(true);
    };
  }, [
    history,
    logout,
    restoreUserSessionFromSessionStorage,
    setInterceptor,
    shareC,
    updateEnterPageFirstRefresh,
  ]);

  useEffect(() => {
    const user = getUserSessionFromSessionStorage();
    if (
      _.isEmpty(user) ||
      user.userId < 0 ||
      _.isEmpty(user.token) ||
      _.isEmpty(user.sharedA)
    ) {
      return;
    }
    setTimeout(() => {
      getMigrationRequest(user)
        .then(verifyRequest => {
          if (!_.isEmpty(verifyRequest)) {
            setMigVerifyOpen(true);
          }
        })
        .catch(e => {});
    }, 5 * 1000);
  }, []);

  useEffect(() => {
    let refreshInterval = null;
    async function init() {
      await refreshAllPendingTxStatus();
      refreshInterval = setInterval(async () => {
        await refreshAllPendingTxStatus();
      }, REFRESH_INTERVAL);
    }
    init();
    return () => clearInterval(refreshInterval);
  }, [refreshAllPendingTxStatus]);

  useEffect(() => {
    if (_.isEmpty(currentAccount)) {
      return;
    }

    if (enterPageFirstRefresh) {
      updateBalance('', CUSTOM_EVMS);
      updateEnterPageFirstRefresh(false);
    } else {
      const interval = setInterval(() => {
        updateBalance('', CUSTOM_EVMS);
      }, 30 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    currentAccount,
    enterPageFirstRefresh,
    updateEnterPageFirstRefresh,
    updateBalance,
  ]);

  useEffect(() => {
    const user = getUserSessionFromSessionStorage();
    if (
      _.isEmpty(user) ||
      user.userId < 0 ||
      _.isEmpty(user.token) ||
      _.isEmpty(user.sharedA)
    ) {
      logoutWallet(history);
    } else if (!restoreFromStorage) {
      initStoreFromStorage();
    } else {
      loadData();
    }
    // eslint-disable-next-line
  }, [history, restoreFromStorage]);

  const handleChangeTab = index => {
    setSelectTab(index);
  };

  const handleChangeAccount = account => {
    if (account.address === currentAccount.address) {
      return;
    }
    updateCurrentAccount(account);
    saveStoreToLocal();
    history.push('/homePage/wallet');
    handleChangeTab(0);
  };

  const onMigrationVerifyClose = info => {
    setMigVerifyOpen(false);
    if (info === 'ok') {
      history.push('/homePage/settings');
      handleChangeTab(2);
    }
  };

  const onImportAccountClose = () => {
    setImportAccountOpen(false);
  };

  const moveHandler = e => {
    eventEmitter.emit(EVENT_PAGE_OPERATION);
  };

  const throttle = useMemo(() => {
    return _.throttle(moveHandler, 60 * 1000, {
      trailing: true,
      leading: false,
    });
  }, []);

  const onClose = info => {
    setSaveShareOpen(false);
    updateShareC({});
  };

  return (
    <div className={classes.home} onMouseMove={ev => throttle(ev)}>
      <Switch>
        <Route
          exact
          strict
          path={`${props.match.url}/wallet`}
          component={WalletComponent}
        />
        <Route
          exact
          strict
          path={`${props.match.url}/activityDetail/:symbol/:address?`}
          component={ActivityDetail}
        />
        <Route
          exact
          strict
          path={`${props.match.url}/transfer/sol`}
          component={TransferSolPage}
        />
        <Route
          exact
          strict
          path={`${props.match.url}/transfer/trx`}
          component={TransferTrxPage}
        />
        <Route
          exact
          strict
          path={`${props.match.url}/transfer/p`}
          component={TransferPolkaFamilyPage}
        />
        <Route
          exact
          strict
          path={`${props.match.url}/transfer/e`}
          component={TransferEthFamilyPage}
        />
        <Route
          exact
          strict
          path={`${props.match.url}/transfer/erc20`}
          component={TransferErc20Page}
        />
        <Route
          exact
          strict
          path={`${props.match.url}/transfer/crossChain`}
          component={TransferCrossChainPage}
        />
        <Route
          exact
          strict
          path={`${props.match.url}/settings`}
          component={SettingsComponent}
        />
        <Route
          exact
          strict
          path={`${props.match.url}/collections`}
          component={CollectionsComponent}
        />
        <Route
          exact
          strict
          path={`${props.match.url}/collectionDetail/:nftId`}
          component={CollectionDetailComponent}
        />
      </Switch>
      <div className={classes.sideNav}>
        <TextLogo />
        <MyAccount
          handleChangeAccount={handleChangeAccount}
          handleImportAccount={() => setImportAccountOpen(true)}
          logout={logout}
        />
        <MyBalance />
        <div className={classes.tabs}>
          {tabs.map((tab, index) => (
            <Typography
              variant="body1"
              component="div"
              key={tab.name}
              onClick={() => {
                handleChangeTab(index);
                history.push(tab.path);
              }}
              className={classNames(
                classes.tab,
                selectTab === index && classes.active,
              )}
            >
              {tab.icon}
              {tab.name}
            </Typography>
          ))}
        </div>
        <div className={classes.footer}>
          <Typography variant="body1" className={classes.copyright}>
            {tHTML('footer.content')}
          </Typography>
          <Social />
        </div>
      </div>

      {getToast()}
      {migVerifyOpen && (
        <MigrationVerifyDialog
          isOpen={migVerifyOpen}
          onClose={onMigrationVerifyClose}
        />
      )}

      {importAccountOpen && (
        <ImportAccountDialog
          isOpen={importAccountOpen}
          onClose={onImportAccountClose}
        />
      )}
      {saveShareOpen && (
        <SaveShareDialog
          isOpen={saveShareOpen}
          share={shareC.shareC}
          onClose={onClose}
        />
      )}
    </div>
  );
}
