const SERVICE_ADDRESS = 'https://auction-api.clover.finance/';
const EXPLORER_URL = 'https://clover.subscan.io/account/';
const DOWNLOAD_WALLET_ERL =
  'https://chrome.google.com/webstore/detail/clover-wallet/nhnkbkgjikgcigadomkphalanndcapjk';

const HEADER_MENU = [
  {
    label: 'Community',
    children: [
      {
        link: 'https://t.me/clover_en/',
        label: 'Telegram',
      },
      {
        link: 'https://discord.gg/kkqmpwheRb',
        label: 'Discord',
      },
      {
        link: 'https://twitter.com/clover_finance/',
        label: 'Twitter',
      },
      {
        link: 'https://projectclover.medium.com/',
        label: 'Medium',
      },
    ],
  },
  {
    label: 'Builders',
    children: [
      {
        link: 'https://docs.clover.finance/clover-eco-incentive-program/clover-dapp-developer-incentive-structure',
        label: 'Developer Incentive Program',
      },
      {
        link: 'https://docs.clover.finance/quick-start/testnet',
        label: 'Testnet',
      },
      {
        link: 'https://discord.gg/RMwkeyKTqE',
        label: 'Developer Discord',
      },
      {
        link: 'https://github.com/clover-network',
        label: 'Github',
      },
    ],
  },
  {
    label: 'Resources',
    children: [
      {
        link: 'https://docs.clover.finance',
        label: 'Documentation',
      },
      {
        link: 'https://clover.finance/litepaper.pdf',
        label: 'Litepaper',
      },
    ],
  },
];

const SOCIAL_ICONS = {
  twitter: 'twitter',
  telegram: 'telegram',
  discord: 'discord',
  medium: 'medium',
};

const SOCIALS = [
  {
    name: 'twitter',
    link: 'https://twitter.com/clover_finance/',
  },
  {
    name: 'telegram',
    link: 'https://t.me/clover_en/',
  },
  {
    name: 'discord',
    link: 'https://discord.com/invite/z2egJBsBWx/',
  },
  {
    name: 'medium',
    link: 'https://projectclover.medium.com/',
  },
];

export {
  HEADER_MENU,
  SOCIAL_ICONS,
  SOCIALS,
  SERVICE_ADDRESS,
  EXPLORER_URL,
  DOWNLOAD_WALLET_ERL,
};
