import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { t } from 'modules/i18n/intl';
import { Button, IconButton, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { CorrectIcon } from 'modules/icons/CorrectIcon';
import { SendIcon } from 'modules/icons/SendIcon';
import { ReceiveIcon } from 'modules/icons/ReceiveIcon';
import { CrossIcon } from 'modules/icons/CrossIcon';
import { CopyIcon } from 'modules/icons/CopyIcon';
import { LinkIcon } from 'modules/icons/LinkIcon';
import { CUSTOM_EVMS, getChainIdBySymbol } from '../../constants/netEnums';
import ReceiveDialog from '../../components/Dialogs/ReceiveDialog';
import Header from '../../components/Header';
import { ToastTooltip } from '../../components/ToastTooltip';
import TokenLogoWithChain from '../../components/TokenLogoWithChain';
import {
  shortenText,
  getColorByStatus,
  isErc20Asset,
  shortenAddress,
  effectiveBalance,
} from '../../utils/helper';
import { canCrosschain } from '../../utils/crossChain';
import {
  useCurrentAccount,
  useCurrentAccountUpdate,
} from '../../state/account/hooks';
import {
  useSelectedAsset,
  useSelectedAssetUpdate,
} from '../../state/app/hooks';
import { useUserSession } from '../../state/user/hooks';
import { useTransactions } from '../../state/transaction/hooks';
import { getAddressFromAccount } from '../../services/WalletService';
import { getTransactions } from '../../services/TransactionService';
import {
  getExplorerUrl,
  getMainTokenLogo,
  polkadotAsset,
} from '../../services/AssetServcie';
import { getAllAssets } from '../../services/AccountService';
import { saveStoreToLocal } from '../../services/LocalstorageService';
import { getSelectedAssetFromSessionStorage } from '../../services/LocalstorageService';
import _ from 'lodash';
import { TransferType } from '../../components/Transfer';
import { logoutWallet } from '../../services/logoutService';
import { solTokenName } from '../../services/SolanaService';
import { useActivityDetailStyles } from './useActivityDetailStyles';
import { LoadingContent } from 'components/LoadingContent';
const dayjs = require('dayjs');

export default function ActivityDetail() {
  const history = useHistory();
  const { symbol, address } = useParams();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showReceiveDialog, setShowReceiveDialog] = useState(false);
  const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);
  const [assetTransactions, setAssetTransactions] = useState([]);
  const [copyIndex, setCopyIndex] = useState(-1);

  const selectedAsset = useSelectedAsset();
  const updateSelectedAsset = useSelectedAssetUpdate();
  const currentAccount = useCurrentAccount();
  const userSession = useUserSession();
  const transactions = useTransactions();
  const updateCurrentAccount = useCurrentAccountUpdate();
  const handleCopy = str => {
    navigator.clipboard.writeText(str);
    if (copyTooltipOpen) {
      return;
    }
    setCopyTooltipOpen(true);
    setTimeout(() => {
      setCopyTooltipOpen(false);
    }, 3 * 1000);
  };

  const findAssetId = () => {
    const assetData = selectedAsset;
    const assets = currentAccount.queryAssets;
    // polkadot
    if (assetData.isPolkadotFamily) {
      const asset = _.find(assets, a => {
        return (
          a.network.platform === 'polkadot' &&
          a.symbol === assetData.tokenSymbol
        );
      });
      return asset?.id;
    }
    // solana
    if (assetData.tokenSymbol === solTokenName) {
      const asset = _.find(assets, a => {
        return (
          a.network.platform === 'solana' && a.symbol === assetData.tokenSymbol
        );
      });
      return asset?.id;
    }

    // not erc20
    if (!assetData.tokenAddress) {
      const asset = _.find(assets, a => {
        return (
          a.network.platform === 'ethereum' &&
          a.symbol === assetData.tokenSymbol
        );
      });
      return asset?.id;
    }

    //erc20
    const chainId = getChainIdBySymbol(assetData.chain, CUSTOM_EVMS);
    const netId = parseInt(chainId);
    const asset = _.find(assets, a => {
      return (
        a.network.platform === 'ethereum' &&
        a.symbol === assetData.tokenSymbol &&
        a.token?.address.toLowerCase() ===
          assetData.tokenAddress.toLowerCase() &&
        a.network.params.chainId === netId
      );
    });
    return asset?.id;
  };

  useEffect(() => {
    if (!_.isEmpty(selectedAsset)) {
      return;
    }

    const asset = getSelectedAssetFromSessionStorage();
    if (!_.isEmpty(asset)) {
      updateSelectedAsset(asset);
      return;
    }

    // both selectedAsset and asset is empty, we have to go back to login
    logoutWallet(history);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (_.isEmpty(selectedAsset)) {
      return;
    }

    let currentAsset;
    if (selectedAsset.isAdd || selectedAsset.isCrosschain) {
      currentAsset = _.find(
        currentAccount.contractAssets,
        ass => ass.tokenAddress === selectedAsset.tokenAddress,
      );
    } else {
      currentAsset = currentAccount[selectedAsset.tokenSymbol];
    }
    if (currentAsset) {
      updateSelectedAsset({
        ...selectedAsset,
        balance: currentAsset.balance,
        amount: effectiveBalance(currentAsset.amount),
      });
    }
    // eslint-disable-next-line
  }, [currentAccount]);
  const [loading, setLoading] = useState(false);
  useEffect(async () => {
    if (_.isEmpty(selectedAsset)) {
      return;
    }
    setLoading(true);
    let trans = [];
    if (selectedAsset.isAdd || selectedAsset.isCrosschain) {
      trans = transactions.filter(
        t =>
          t.from ===
            getAddressFromAccount(
              selectedAsset.chain,
              currentAccount,
              CUSTOM_EVMS,
            ) &&
          t.tokenSymbol === selectedAsset.tokenSymbol &&
          t.networkSymbol === selectedAsset.chain,
      );
    } else {
      trans = transactions.filter(
        t =>
          t.from ===
            getAddressFromAccount(
              selectedAsset.tokenSymbol,
              currentAccount,
              CUSTOM_EVMS,
            ) &&
          t.tokenSymbol === selectedAsset.tokenSymbol &&
          t.networkSymbol === selectedAsset.tokenSymbol,
      );
    }
    const getServerLatesAssets = await getAllAssets(
      currentAccount.accountId,
      currentAccount.requestPrivateKey,
    );
    if (getServerLatesAssets && getServerLatesAssets.length > 0) {
      const tempAccount = { ...currentAccount };
      tempAccount.queryAssets = getServerLatesAssets;
      updateCurrentAccount(tempAccount);
      saveStoreToLocal();
    }
    const queryAssetId = findAssetId();
    let queryTransactions = [];
    if (queryAssetId) {
      queryTransactions = await getTransactions(
        currentAccount,
        queryAssetId,
        currentAccount.requestPrivateKey,
        selectedAsset,
      );
    }
    const displayTrans = _.concat(trans, queryTransactions);
    const sortTxans = displayTrans.sort((a, b) => {
      return dayjs(b.date) - dayjs(a.date);
    });
    setAssetTransactions(sortTxans);
    setLoading(false);
  }, [transactions]);

  const getIcon = activity => {
    if (activity.isReceive) {
      if (activity.receiveType === 'crossChain') {
        return <CrossIcon />;
      } else {
        return <ReceiveIcon />;
      }
    } else {
      return activity.txnType === TransferType.CROSS_CHAIN ? (
        <CrossIcon />
      ) : (
        <SendIcon />
      );
    }
  };

  const classes = useActivityDetailStyles();

  return (
    userSession.userId >= 0 &&
    !_.isEmpty(selectedAsset) && (
      <div className={classes.root}>
        <Header
          goBack={() => history.push('/homePage/wallet')}
          title={t('asset-detail.title')}
          address={address}
        />
        <div className={classes.tokenInfo}>
          {selectedAsset.isAdd ? (
            <TokenLogoWithChain
              logoWidth="60px"
              chainWidth="20px"
              tokenAddress={selectedAsset.tokenAddress}
              chain={selectedAsset.chain}
            />
          ) : selectedAsset.isCrosschain ? (
            <div className={classes.crossChainLogo}>
              <img
                width={60}
                height={60}
                src={getMainTokenLogo(selectedAsset.tokenSymbol)}
                alt=""
              />
              <img
                width={20}
                height={20}
                src={getMainTokenLogo(selectedAsset.chain)}
                alt=""
              />
            </div>
          ) : (
            <img
              width={60}
              height={60}
              src={getMainTokenLogo(selectedAsset.tokenSymbol)}
              alt=""
            />
          )}
          <Typography variant="h1" component="div" style={{ marginTop: 16 }}>
            {`${selectedAsset.amount} ${selectedAsset.tokenSymbol}`}
            <Typography variant="body1" color="textSecondary">
              ${selectedAsset.price}
            </Typography>
          </Typography>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              size="large"
              startIcon={<SendIcon />}
              onClick={() => {
                if (selectedAsset.tokenSymbol === 'SOL') {
                  history.push(`/homePage/transfer/sol`);
                } else if (selectedAsset.tokenSymbol === 'TRX') {
                  history.push(`/homePage/transfer/trx`);
                } else if (
                  selectedAsset.tokenAddress &&
                  isErc20Asset(selectedAsset.tokenAddress)
                ) {
                  history.push(`/homePage/transfer/erc20`);
                } else if (polkadotAsset(selectedAsset)) {
                  history.push(`/homePage/transfer/p`);
                } else {
                  history.push(`/homePage/transfer/e`);
                }
              }}
            >
              {t('action.send')}
            </Button>
            <Button
              variant="contained"
              size="large"
              startIcon={<ReceiveIcon />}
              onClick={() => setShowReceiveDialog(true)}
            >
              {t('action.receive')}
            </Button>
            {canCrosschain(
              selectedAsset.tokenSymbol,
              selectedAsset.tokenAddress,
            ) && (
              <Button
                variant="contained"
                size="large"
                startIcon={<CrossIcon />}
                onClick={() => history.push(`/homePage/transfer/crossChain`)}
              >
                {t('action.cross-chain')}
              </Button>
            )}
          </div>
        </div>
        <div className={classes.list}>
          <Typography variant="h3" style={{ marginBottom: 32 }}>
            {t('asset-detail.activity')}
          </Typography>
          {assetTransactions.length > 0 ? (
            <div className={classes.listContent}>
              {assetTransactions.map((activity, index) => (
                <div key={`activity${index}`}>
                  <div
                    className={classNames(
                      classes.row,
                      index === currentIndex && classes.current,
                    )}
                    onClick={() => {
                      if (index === currentIndex) {
                        setCurrentIndex(-1);
                      } else {
                        setCurrentIndex(index);
                      }
                    }}
                  >
                    <div className={classes.info}>
                      {getIcon(activity)}
                      <Typography variant="body1" component="div">
                        <div>
                          {activity.isReceive
                            ? t('action.receive')
                            : t('action.send')}{' '}
                          {activity.tokenSymbol}
                          <img
                            width={16}
                            height={16}
                            src={getMainTokenLogo(activity.tokenSymbol)}
                            alt=""
                          />
                        </div>
                        <Typography variant="body1" color="textSecondary">
                          {dayjs(activity.date).format('MMM D, YYYY')}
                        </Typography>
                      </Typography>
                    </div>
                    <Typography variant="body1" component="div">
                      {activity.isReceive
                        ? t('asset-detail.from')
                        : t('asset-detail.recipient')}
                      <Typography variant="body2" color="textSecondary">
                        {activity.isReceive
                          ? shortenAddress(activity.from)
                          : shortenAddress(activity.to)}
                      </Typography>
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      style={{ textAlign: 'left' }}
                    >
                      {activity.isReceive
                        ? `+${shortenText(activity.amount, 18)} ${
                            activity.tokenSymbol
                          }`
                        : `-${shortenText(activity.amount, 18)} ${
                            activity.tokenSymbol
                          }`}
                    </Typography>
                    <Typography
                      style={{
                        color: `${getColorByStatus(activity.status)}`,
                        textAlign: 'right',
                      }}
                    >
                      {activity.status}
                    </Typography>
                  </div>
                  {index === currentIndex && (
                    <div className={classes.rowDetail}>
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.item}
                      >
                        {t('asset-detail.from')}
                        <Typography>{shortenAddress(activity.from)}</Typography>
                      </Typography>
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.item}
                      >
                        {t('asset-detail.to')}
                        <Typography>{shortenAddress(activity.to)}</Typography>
                      </Typography>
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.item}
                      >
                        {t('asset-detail.amount')}
                        <Typography>
                          {activity.amount} {activity.tokenSymbol}
                        </Typography>
                      </Typography>
                      {!activity.isReceive && (
                        <Typography
                          variant="body1"
                          component="div"
                          className={classes.item}
                        >
                          {t('asset-detail.txn-fee')}
                          <Typography>
                            {activity.fee}{' '}
                            {activity.crossFee ? ` + ${activity.crossFee}` : ''}
                          </Typography>
                        </Typography>
                      )}
                      {!activity.isReceive && (
                        <Typography
                          variant="body1"
                          component="div"
                          className={classes.item}
                        >
                          {t('asset-detail.total')}
                          <Typography>
                            {activity.total} {activity.tokenSymbol}{' '}
                            {activity.isErc20 ? ` + ${activity.fee}` : ''}
                          </Typography>
                        </Typography>
                      )}
                      <Typography
                        variant="body1"
                        component="div"
                        className={classes.item}
                      >
                        {t('asset-detail.hash')}
                        <Typography
                          component="div"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          {shortenAddress(activity.txnHash)}
                          <ToastTooltip
                            open={copyIndex === index && copyTooltipOpen}
                            message="Copied!"
                            icon={CorrectIcon}
                          >
                            <IconButton
                              variant="outlined"
                              style={{ color: 'white' }}
                              onClick={() => {
                                setCopyIndex(index);
                                handleCopy(activity.txnHash);
                              }}
                            >
                              <CopyIcon />
                            </IconButton>
                          </ToastTooltip>
                          <IconButton
                            variant="outlined"
                            style={{ color: 'white' }}
                            onClick={() => {
                              if (
                                getExplorerUrl(
                                  activity.networkSymbol,
                                  'transfer',
                                  activity.txnHash,
                                ) !== ''
                              ) {
                                window.open(
                                  getExplorerUrl(
                                    activity.networkSymbol,
                                    'transfer',
                                    activity.txnHash,
                                  ),
                                );
                              }
                            }}
                          >
                            <LinkIcon />
                          </IconButton>
                        </Typography>
                      </Typography>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : loading ? (
            <LoadingContent />
          ) : (
            <div className={classes.noContent}>
              {t('asset-detail.no-asset')}
            </div>
          )}
        </div>
        <ReceiveDialog
          address={address}
          token={symbol}
          isOpen={showReceiveDialog}
          onClose={() => setShowReceiveDialog(false)}
        />
      </div>
    )
  );
}
