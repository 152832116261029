import _ from 'lodash';
import { useUserSession } from '../state/user/hooks';
import { useCallback } from 'react';
import { combineSignKey } from './loginService';
import { Account } from '../state/account/types';
import {
  useAccounts,
  useAccountsUpdate,
  useCurrentAccountUpdate,
} from '../state/account/hooks';
import {
  ChainSS58,
  getEthAddress,
  getSubstrateAddress,
  KeyPairType,
} from './WalletService';
import { saveStoreToLocal } from './LocalstorageService';
import { useRestoreFromStorage } from '../state/app/hooks';
import * as bip32 from 'bip32';
import axios from 'axios';
import { IS_DEV } from '../constants/api';
import { sha256 } from 'js-sha256';
import { getAddressFromMnemonic } from './SolanaService';
import { getTrxAddressFromMnemonic } from './TrxService';

const bip39 = require('bip39');
const CryptoJS = require('crypto-js');
export const host = IS_DEV
  ? 'https://wallet-api-dev.clover.finance/api/'
  : 'https://wallet-api.clover.finance/api/';

interface IParams {
  rpc: string;
  wss: string;
  chainId: number;
  ss58Format: number;
}

interface INetwork {
  id: string;
  name: string;
  platform: string;
  logoURI: string;
  blockExplorerURI: string;
  addressRegex: string;
  params: IParams;
  symbol: string;
  balance: number;
}

export async function createAccount(
  seedWords: string,
  keypairType: string,
  isLoginAccount: boolean,
  alias: string,
  loginPubKey: string,
): Promise<Account> {
  const type = keypairType === 'sr25519' ? 0 : 1;
  const seedBuf = await bip39.mnemonicToSeed(`${seedWords}:${type}`);
  const node = bip32.fromSeed(seedBuf);
  const child = node.derivePath('m/1/1');
  const requestPrivateKey = child.privateKey?.toString('hex');
  const requestPubKey = child.publicKey.toString('hex');
  const nw = await getNetworks(requestPrivateKey as string);
  const solAddress = await getAddressFromMnemonic(seedWords);
  const trxAddress = await getTrxAddressFromMnemonic(seedWords);
  const result: Account = {
    address: getSubstrateAddress(seedWords, ChainSS58.CLV, keypairType),
    isLoginAccount,
    alias,
    keypairType,
    seedWords: isLoginAccount
      ? ''
      : CryptoJS.AES.encrypt(seedWords, loginPubKey).toString(),
    dotAddress: getSubstrateAddress(seedWords, ChainSS58.DOT, keypairType),
    skuAddress: getSubstrateAddress(seedWords, ChainSS58.SKU, keypairType),
    ksmAddress: getSubstrateAddress(seedWords, ChainSS58.KSM, keypairType),
    edgAddress: getSubstrateAddress(seedWords, ChainSS58.EDG, keypairType),
    cruAddress: getSubstrateAddress(seedWords, ChainSS58.CRU, keypairType),
    trxAddress,
    karAddress: getSubstrateAddress(seedWords, ChainSS58.KAR, keypairType),
    sdnAddress: getSubstrateAddress(seedWords, ChainSS58.SDN, keypairType),
    phaAddress: getSubstrateAddress(seedWords, ChainSS58.PHA, keypairType),
    bncAddress: getSubstrateAddress(seedWords, ChainSS58.BNC, keypairType),
    kiltAddress: getSubstrateAddress(seedWords, ChainSS58.KILT, keypairType),
    kmaAddress: getSubstrateAddress(seedWords, ChainSS58.KMA, keypairType),
    solAddress,
    evmAddress: getEthAddress(seedWords),
    hiddenAssets: ['XDAI', 'MATIC', 'OKT', 'CRU', 'TRX', 'KAR', 'SDN', 'PHA', 'MOVR', 'BNC', 'KILT', 'KMA'],
    contractAssets: [],
    tokens: [],
    requestPrivateKey: requestPrivateKey,
    requestPubKey: requestPubKey,
    accountId: '',
    queryAssets: [],
  };
  const ret = await updatePubKey(
    result,
    nw.data,
    requestPubKey,
    requestPrivateKey as string,
    solAddress,
  );
  result.accountId = ret._id;
  const allAssets = await getAllAssets(ret._id, requestPrivateKey as string);
  result.queryAssets = allAssets;
  return result;
}

const getNetworks = async (requestPrivateKey: string) => {
  const val = 'get|/api/networks|{}';
  const signature = getSignature(val, requestPrivateKey);
  let networks = await axios.get(`${host}networks`, {
    headers: { 'X-Signature': signature },
  });
  return networks;
};

export const getAllAssets = async (
  accountId: string,
  requestPrivateKey: string,
) => {
  const val = `get|/api/assets?accountId=${accountId}&skip=0&limit=10000|{}`;
  const signature = getSignature(val, requestPrivateKey);
  let res = await axios.get(
    `${host}assets?accountId=${accountId}&skip=0&limit=10000`,
    {
      headers: { 'X-Signature': signature, 'X-Identity': accountId },
    },
  );
  return res.data;
};

const toUTF8Array = (str: string) => {
  var utf8 = [];
  for (var i = 0; i < str.length; i++) {
    var charcode = str.charCodeAt(i);
    if (charcode < 0x80) utf8.push(charcode);
    else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(
        0xe0 | (charcode >> 12),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f),
      );
    }
    // surrogate pair
    else {
      i++;
      // UTF-16 encodes 0x10000-0x10FFFF by
      // subtracting 0x10000 and splitting the
      // 20 bits of 0x0-0xFFFFF into two halves
      charcode =
        0x10000 + (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f),
      );
    }
  }
  return utf8;
};

const updatePubKey = async (
  account: Account,
  networks: INetwork[],
  requestPubKey: string,
  requestPrivateKey: string,
  solAddress: string,
) => {
  const accounts = [
    {
      address: account.evmAddress.toLowerCase(),
      networkIds: networks
        .filter(n => n.platform === 'ethereum')
        .map(n => n.id),
    },
  ];

  let ss58ToIdMap: { [index: number]: string[] } = {};
  networks.forEach(n => {
    if (n.platform === 'polkadot') {
      if (ss58ToIdMap[n.params.ss58Format]) {
        ss58ToIdMap[n.params.ss58Format].push(n.id);
      } else {
        ss58ToIdMap[n.params.ss58Format] = [n.id];
      }
    } else if (n.platform === 'solana') {
      accounts.push({
        address: solAddress,
        networkIds: [n.id],
      });
    }
  });

  _.forOwn(ss58ToIdMap, (value, key) => {
    if (key === '42') {
      accounts.push({
        address: account.address,
        networkIds: value,
      });
    } else if (key === '7') {
      accounts.push({
        address: account.edgAddress,
        networkIds: value,
      });
    } else if (key === '2') {
      accounts.push({
        address: account.ksmAddress,
        networkIds: value,
      });
    } else if (key === '0') {
      accounts.push({
        address: account.dotAddress,
        networkIds: value,
      });
    } else if (key === '66') {
      accounts.push({
        address: account.cruAddress,
        networkIds: value,
      });
    } else if (key === '8') {
      accounts.push({
        address: account.karAddress,
        networkIds: value,
      });
    } else if (key === '5') {
      accounts.push({
        address: account.sdnAddress,
        networkIds: value,
      });
    } else if (key === '30') {
      accounts.push({
        address: account.phaAddress,
        networkIds: value,
      });
    } else if (key === '6') {
      accounts.push({
        address: account.bncAddress,
        networkIds: value,
      });
    } else if (key === '38') {
      accounts.push({
        address: account.kiltAddress,
        networkIds: value,
      });
    } else if (key === '66') {
      accounts.push({
        address: account.cruAddress,
        networkIds: value,
      });
    } else if (key === '78') {
      accounts.push({
        address: account.kmaAddress,
        networkIds: value,
      });
    }
  });

  const data = {
    name: account.alias,
    requestPubKey: requestPubKey,
    accounts: accounts,
  };

  const toSigString = `post|/api/wallets|${JSON.stringify(data)}`;
  const sig = getSignature(toSigString, requestPrivateKey);

  const resp = await axios.post(`${host}wallets`, data, {
    headers: { 'X-Signature': sig },
  });

  return resp.data;
};

export const getSignature = (val: string, key: string) => {
  const bytes = toUTF8Array(val);
  const sh = sha256.array(sha256.array(bytes));
  const child = bip32.fromPrivateKey(
    Buffer.from(key, 'hex'),
    Buffer.alloc(32),
    undefined,
  );
  const sig = child.sign(Buffer.from(sh));
  return sig.toString('hex');
};

export function mergeAccounts(accounts: Array<Account>, account: Account) {
  const accountIdxToUpdate = accounts.findIndex(
    a => a.address === account.address,
  );
  const newAccounts = _.cloneDeep(accounts);
  if (accountIdxToUpdate === -1) {
    return [...newAccounts, { ...account }];
  } else {
    newAccounts[accountIdxToUpdate] = account;
  }

  return newAccounts;
}

export function emptyAccount(account: Account) {
  return _.isEmpty(account);
}

// with network request edition
export function useConstructLoginAccount(): () => Promise<Account | null> {
  const userSession = useUserSession();
  const updateAccounts = useAccountsUpdate();
  const accounts = useAccounts();
  const updateCurrentAccount = useCurrentAccountUpdate();
  const restoreFromStorage = useRestoreFromStorage();
  return useCallback(async () => {
    if (
      userSession.userId < 0 ||
      userSession.sharedA === '' ||
      !restoreFromStorage
    ) {
      return null;
    }

    let seedWords = await combineSignKey(
      userSession.sharedA,
      userSession.userId,
      userSession.token,
    );
    try {
      getEthAddress(seedWords);
    } catch (e) {
      seedWords = null;
    }

    if (_.isEmpty(seedWords)) {
      return null;
    }

    const loginAccount = await createAccount(
      seedWords,
      KeyPairType.sr25519,
      true,
      userSession.userName,
      userSession.publicKey,
    );

    const existedLoginAccount = _.find(
      accounts,
      a => a.address === loginAccount.address,
    );
    if (!_.isEmpty(existedLoginAccount)) {
      return existedLoginAccount;
    }

    updateAccounts(mergeAccounts(accounts, loginAccount));
    updateCurrentAccount(loginAccount);
    saveStoreToLocal();
    return loginAccount;
  }, [
    userSession,
    accounts,
    updateAccounts,
    updateCurrentAccount,
    restoreFromStorage,
  ]);
}

// without network request edition
export function useConstructLoginAccountWithSeedWords(): (
  seedWords: string,
) => Promise<Account | null> {
  const userSession = useUserSession();
  const updateAccounts = useAccountsUpdate();
  const accounts = useAccounts();
  const updateCurrentAccount = useCurrentAccountUpdate();
  const restoreFromStorage = useRestoreFromStorage();

  return useCallback(
    async (seedWords: string) => {
      if (_.isEmpty(seedWords) || !restoreFromStorage) {
        return null;
      }

      const loginAccount = await createAccount(
        seedWords,
        KeyPairType.sr25519,
        true,
        userSession.userName,
        userSession.publicKey,
      );
      updateAccounts(mergeAccounts(accounts, loginAccount));
      updateCurrentAccount(loginAccount);
      saveStoreToLocal();
      return loginAccount;
    },
    [
      userSession,
      accounts,
      updateAccounts,
      updateCurrentAccount,
      restoreFromStorage,
    ],
  );
}

export function useImportAccountWithSeedWords(): (
  seedWords: string,
  keyPairType: string,
) => Promise<Account | null> {
  const userSession = useUserSession();
  const updateAccounts = useAccountsUpdate();
  const accounts = useAccounts();
  const updateCurrentAccount = useCurrentAccountUpdate();

  return useCallback(
    async (seedWords: string, keyPairType: string) => {
      if (_.isEmpty(seedWords)) {
        return null;
      }

      const importAccount = await createAccount(
        seedWords,
        keyPairType,
        false,
        `Account${accounts.length}`,
        userSession.publicKey,
      );
      updateAccounts(mergeAccounts(accounts, importAccount));
      updateCurrentAccount(importAccount);
      saveStoreToLocal();
      return importAccount;
    },
    [accounts, updateAccounts, updateCurrentAccount, userSession.publicKey],
  );
}
